import { GET, POST, PUT } from 'api'
import { dateToString } from 'utils/date'

export const getTeacherList = () => GET({ url: 'admin/teachers/list' })

export const getTeacherListQuery = ({ subjectId, active = true }) => {
  let query = `v=1&active=${active}`
  if (subjectId) query += `&subject=${subjectId}`
  return GET({
    url: `admin/teachers/list?${query}`
  })
}

export const getTeacherDetails = ({ teacherId }) =>
  GET({
    url: `admin/teachers/${teacherId}/profile`
  })

export const getTeacherClasses = ({ teacherId, date }) => {
  const formattedDate = dateToString(date)
  return GET({
    url: `admin/teachers/${teacherId}/classes?date=${formattedDate}`
  })
}

export const updateTeacher = ({ teacherId, update }) =>
  PUT({
    url: `admin/teachers/${teacherId}/profile`,
    data: { update }
  })
