import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getTeacherDetails } from 'api/teachers'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import SubjectTypeSelector from '../../../elements/SubjectTypeSelector/SubjectTypeSelector'
import TeacherAvatar from 'elements/TeacherAvatar/TeacherAvatar'
import styles from './DatosProfesor.module.css'

const CLASSROOM_LIMIT = 12

const DatosProfesor = () => {
  const { id } = useParams()
  const { setErrorMessage } = useMessageSnackbarActions()

  const [teacher, setTeacher] = useState({
    name: '',
    email: '',
    subjects: [],
    active: true,
    eligible: true,
    type: '',
    classroomLimit: CLASSROOM_LIMIT
  })

  useEffect(() => {
    getTeacherDetails({ teacherId: id })
      .then(setTeacher)
      .catch(() => setErrorMessage('No se puede mostrar la ficha'))
  }, [id, setErrorMessage])

  return (
    <Card className='bordered_shadow card_wrapper' variant='outlined'>
      <CardHeader title='Ficha' />
      <CardContent>
        <div className={styles.cardContentTop}>
          <TeacherAvatar teacher={teacher} />
          <div className={styles.basicInfo}>
            <TextField
              margin='dense'
              label='Nombre'
              value={teacher.name}
              disabled
              fullWidth
            />
            <TextField
              margin='dense'
              label='Correo electrónico'
              value={teacher.email}
              disabled
              fullWidth
            />
          </div>
        </div>
        <SubjectTypeSelector value={teacher.type || ''} fullWidth disabled />
        <FormControlLabel
          margin='dense'
          control={<Checkbox checked={teacher.active} disabled />}
          label='Activo'
        />
      </CardContent>
    </Card>
  )
}

export default DatosProfesor
