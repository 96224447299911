import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getStudentDetails } from 'api/students'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import StudentForm from '../../../sections/FormularioAlumno/StudentForm'
import Spinner from '../../../elements/Spinner/Spinner'

const DatosAlumno = () => {
  const { id } = useParams()

  const { setErrorMessage } = useMessageSnackbarActions()

  const [student, setStudent] = useState({})
  const [isLoading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    setLoading(true)
    getStudentDetails({ studentId: id })
      .then(data => setStudent(data))
      .catch(() => setErrorMessage('No se puede mostrar la ficha'))
      .finally(() => setLoading(false))
  }, [id, setErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (isLoading || !student) return <Spinner />
  return (
    <StudentForm
      student={student}
      isLoading={isLoading}
      mainTitle='Editar ficha'
      onSubmit={() => {}}
      isEditMode={true}
    />
  )
}

export default DatosAlumno
