import { GET } from 'api'
import { makeCustomQueryAndProject } from 'utils'

export const findSubjects = (query = {}, project = []) =>
  GET({
    url: makeCustomQueryAndProject({
      query,
      project,
      url: 'admin/subjects/list'
    })
  })
export const findSubjectsWithTeacherCount = () =>
  GET({
    url: 'admin/subjects/subjects-with-teachers-count'
  })
