export const dashboardOptions = {
  top: {
    Dashboard: {
      to: '/',
      icon: 'home',
      title: 'Dashboard'
    },
    Students: {
      to: '/students',
      icon: 'face',
      title: 'Alumnos'
    },
    Staff: {
      isCollapse: true,
      title: 'Personal',
      icon: 'people',
      to: '/staff',
      options: [
        {
          title: 'Profesores',
          linkTo: '/staff/teachers',
          icon: 'account_circle'
        },
        {
          title: 'Administradores',
          linkTo: '/staff/admins',
          icon: 'admin_panel_settings'
        }
      ]
    },
    Ticketing: {
      to: '/ticketing',
      icon: 'contact_mail',
      title: 'Ticketing'
    },
    SpecialRequests: {
      to: '/special-requests',
      icon: 'warning',
      title: 'Peticiones especiales',
      showCount: true
    }
  },
  bottom: {
    Connections: {
      to: '/online/connections',
      icon: 'settings_input_antenna',
      title: 'Conexiones'
    }
  }
}
