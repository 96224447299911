import { GET } from 'api'
import { makeCustomQueryAndProject } from 'utils'

/**
 *
 * @param {object} query
 * @param {string[]} project
 * @param {{page:number, pageSize:number, sort?:object}} [pagination]
 * @returns {Promise<{students: object[], count?: number}>} count: if query contains useCount=true
 */
export const findStudents = (query = {}, project = [], pagination) =>
  GET({
    url: makeCustomQueryAndProject({
      query,
      project,
      pagination,
      url: 'admin/students/custom-query/'
    })
  })

export const getStudentDetails = ({ studentId }) =>
  GET({
    url: `admin/students/${studentId}/profile`
  })

export const getStudentClasses = ({ studentId, month, year }) =>
  GET({
    url: `admin/students/${studentId}/classes?month=${month}&year=${year}`
  })

export const getStudentNotebooks = ({ studentId, month, year }) =>
  GET({
    url: `admin/students/${studentId}/notebooks?month=${month}&year=${year}`
  })

export const getStudentPhotos = ({ studentId, month, year }) =>
  GET({
    url: `admin/students/${studentId}/photos?month=${month}&year=${year}`
  })
