import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { propEq, sortBy } from 'ramda'
import { getTeacherList, updateTeacher } from 'api/teachers'
import { dateToString, dateFromString } from 'utils/date'
import { textFilter } from 'utils/table'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../elements/SearchInput/SearchInput'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import TeacherAvatar from '../../elements/TeacherAvatar/TeacherAvatar'
import styles from './ListadoProfesores.module.css'

const StatusButton = ({ active, onClick }) => (
  <IconButton
    className={styles.statusButton}
    aria-label='Profesor activ@'
    onClick={onClick}
  >
    <StatusIcon active={active} />
  </IconButton>
)

const StatusIcon = ({ active }) => {
  if (active)
    return (
      <i title='Activo' className='material-icons' style={{ color: '#8cc63f' }}>
        done
      </i>
    )
  else
    return (
      <i
        title='Inactivo'
        className='material-icons'
        style={{ color: '#f0513c' }}
      >
        clear
      </i>
    )
}

const ListadoProfesores = () => {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [teachers, setTeachers] = useState([])
  const [search, setSearch] = useState('')

  const filterSearch = subject => {
    return textFilter({
      object: subject,
      fields: ['name', 'email'],
      search: search
    })
  }
  useEffect(() => {
    getTeacherList()
      .then(sortBy(propEq('active', false)))
      .then(setTeachers)
      .catch(() => setErrorMessage('No se puede mostrar el listado'))
  }, [setErrorMessage])

  const handleProfesorEligibleChange = teacher => () => {
    updateTeacher({
      teacherId: teacher._id,
      update: {
        eligible: !teacher.eligible
      }
    })
      .then(() => {
        setTeachers(currentTeachers => {
          return currentTeachers.map(currentTeacher => {
            if (teacher._id !== currentTeacher._id) return currentTeacher
            else return { ...teacher, eligible: !teacher.eligible }
          })
        })
      })
      .catch(e => setErrorMessage(e.message))
  }

  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          {
            title: '',
            key: 'avatar',
            align: 'left',
            style: { width: 45 },
            sortDisabled: true
          },
          { title: 'Nombre profesor', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Último Login', key: 'lastLogin', sortDisabled: true },
          {
            title: 'Elegible',
            key: 'eligible',
            sortDisabled: true,
            align: 'center'
          },
          {
            title: 'Acceso',
            key: 'active',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={teachers.filter(filterSearch).map(teacher => ({
          _id: teacher._id,
          avatar: (
            <Link to={`/staff/teachers/${teacher._id}`}>
              <TeacherAvatar teacher={teacher} useThumbnail />
            </Link>
          ),
          email: teacher.email,
          name: (
            <Link to={`/staff/teachers/${teacher._id}`}>{teacher.name}</Link>
          ),
          lastLogin: teacher.lastSuccessfullLogin
            ? dateToString(teacher.lastSuccessfullLogin)
            : '--',
          active: <StatusButton active={teacher.active} disabled />,
          eligible: (
            <StatusButton
              active={teacher.eligible}
              onClick={handleProfesorEligibleChange(teacher)}
            />
          )
        }))}
      />
    </div>
  )
}

export default ListadoProfesores
