//@ts-check
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Chip, FormControlLabel, Tooltip } from '@material-ui/core'
import JoditEditor from 'jodit-react'
import { findSpecialRequests, toggleTicketStatus } from 'api/ticketing'
import { getCurrentMonth, dateTimeToString } from 'utils/date'
import { textFilter } from 'utils/table'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import SearchInput from 'elements/SearchInput/SearchInput'
import MonthSelector from 'elements/MonthSelector/MonthSelector'
import YearSelector from 'elements/YearSelector/YearSelector'
import InformationTable from 'components/InformationTable/InformationTable'
import {
  TicketStatus,
  TicketStatusLabels,
  TicketTypeLabels,
  TicketTypes
} from 'shared/constants'
import styles from './SpecialRequests.module.css'

/**@type {Partial<import('jodit/types/config.js').Config>} */
const joditEditorConfig = {
  toolbar: false,
  iframe: true,
  style: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif'
  },
  readonly: true,
  // @ts-ignore
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  minHeight: 30,
  height: 50,
  showTooltip: true
}

const ticketTypeColor = {
  [TicketTypes.APP_HELP]: 'primary',
  [TicketTypes.BUDGET]: 'secondary',
  [TicketTypes.REGISTER]: 'default'
}

function SpecialRequests() {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [tickets, setTickets] = useState([])
  const [search, setSearch] = useState('')
  const [showClosed, setShowClosed] = useState(false)
  const [date, setDate] = useState(getCurrentMonth())

  const handleDateChange = key => e => {
    const value = e.target.value
    setDate(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }
  const fetchData = useCallback(
    () =>
      findSpecialRequests(date)
        .then(setTickets)
        .catch(() => setErrorMessage('No se puede mostrar el listado')),
    [date, setErrorMessage]
  )

  const handleToggleTicketStatus = id => {
    toggleTicketStatus(id)
      .then(fetchData)
      .catch(() =>
        setErrorMessage('Ha ocurrido un error cambiando el estado del ticket')
      )
  }

  const filterSearch = ticket => {
    return textFilter({
      object: ticket,
      fields: ['subject', 'studentName', 'thread.0.content'],
      search: search
    })
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const closedTickets = tickets.filter(
    ticket => ticket.status === TicketStatus.CLOSED
  )
  return (
    <>
      <div className={styles.toolbar}>
        <SearchInput
          value={search || ''}
          onChange={e => setSearch(e.target.value)}
          autoFocus
        />
        <MonthSelector
          value={date.month}
          onChange={handleDateChange('month')}
        />
        <YearSelector value={date.year} onChange={handleDateChange('year')} />
        <FormControlLabel
          control={
            <Checkbox
              checked={showClosed}
              onChange={() => setShowClosed(state => !state)}
            />
          }
          label={`Ver cerrados (${closedTickets.length})`}
        />
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Tipo', key: 'type', align: 'left' },
          { title: 'Asunto', key: 'subject', align: 'left' },
          { title: 'Alumno/Lead', key: 'student' },
          { title: 'Primer mensaje', key: 'firstMessage' },
          {
            title: 'Nº de mensajes',
            key: 'length'
          },
          {
            title: 'Estado',
            key: 'status'
          },
          {
            title: 'Fecha apertura',
            key: 'createdAt'
          },
          {
            title: 'Última interacción',
            key: 'updatedAt'
          }
        ]}
        data={tickets
          .filter(ticket => {
            if (showClosed) return ticket.status === TicketStatus.CLOSED
            return ticket.status === TicketStatus.OPEN
          })
          .filter(filterSearch)
          .map(ticket => ({
            type: (
              <Chip
                label={TicketTypeLabels[ticket.type] || '??'}
                size='small'
                color={ticketTypeColor[ticket.type]}
              />
            ),
            subject: (
              <Link to={`/ticketing/${ticket.id}`}>{ticket.subject}</Link>
            ),
            student: (
              <a
                href={
                  ticket.isLead
                    ? `https://agora.mundoestudiante.cloud/leads/${ticket.studentId}/ficha`
                    : `https://agora.mundoestudiante.cloud/alumnos/${ticket.studentId}/ficha`
                }
                target='_blank'
              >
                {ticket.studentName}
              </a>
            ),
            firstMessage: (
              <JoditEditor
                value={ticket.thread[0]?.content}
                config={joditEditorConfig}
                tabIndex={ticket.id}
              />
            ),
            status: (
              <Tooltip
                title={ticket.status === TicketStatus.OPEN ? 'Cerrar' : 'Abrir'}
              >
                <Chip
                  label={TicketStatusLabels[ticket.status] || '??'}
                  size='small'
                  color={
                    ticket.status === TicketStatus.OPEN ? 'primary' : undefined
                  }
                  variant='outlined'
                  onClick={() => handleToggleTicketStatus(ticket.id)}
                />
              </Tooltip>
            ),
            length: ticket.thread.length,
            createdAt: dateTimeToString(ticket.createdAt),
            updatedAt: dateTimeToString(ticket.updatedAt)
          }))}
      />
    </>
  )
}
export default SpecialRequests
